<template>
  <keep-alive>
    <div>
      <!-- 欢迎使用 -->
      <div class="main2">
         <p>欢迎使用&nbsp;合规知识库</p>
      </div>
      <!-- 请选择您关注的条线 -->
      <div class="main3">
        <p>请选择您关注的条线</p>
      </div>
      <div class="home_box">
        <div>
          <dl @click="goList(1)">
            <dt><img src="../../assets/image/home/yiwu1.png" /></dt>
            <dd>
              <h3>义务清单</h3>
              <p>满满干货，明确底线</p>
          
            </dd>
          </dl>
          <dl @click="goList(2)">
            <dt><img src="../../assets/image/home/guizhang(2).png" /></dt>
            <dd>
              <h3>规章制度</h3>
              <p>监管规定，应知应会</p>
            </dd>
          </dl>
          <dl @click="goList(3)">
            <dt><img src="../../assets/image/home/chufa@2x.png" /></dt>
            <dd>
              <h3>处罚清单</h3>
              <p>全量清单，聚焦场景</p>
            </dd>
          </dl>
          <dl @click="goList(4)">
            <dt><img src="../../assets//image/home/hegui@2x.png" /></dt>
            <dd>
              <h3>合规资讯</h3>
              <p>合规热点，专业解读</p>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </keep-alive>
</template>

<script>
import { eventBus } from "@/main";
export default {
  data() {
    return {
      value: "",
      activeIndex: "1",
      activeIndex2: "2",
      line: [],
      list: [],
      value: "",
      id: "",
      type: "",
      path: "",
      permName: "",
    };
  },
  mounted() {
    this.line = this.$store.state.line;
   // this.permName = this.line[0].permName;
    eventBus.$on("changeLine", (item) => {
      this.value = item;
    });
    this.line = this.$store.state.line;
    this.value = sessionStorage.getItem("line");
  },
  methods: {
    a() {
      // this.line = JSON.parse(this.$route.query.data);
      // this.$emit("child-event", this.line);
    },
    change(item) {
      //条线id
      this.$store.commit("getId", item.value);
      this.id = this.$store.state.Id;
      eventBus.$emit("changeLine", item.label);
      sessionStorage.setItem("line", item.label);
    },
    //四个导航
    goList(item) {
      sessionStorage.setItem("index", item - 1);
      eventBus.$emit("headerLine", item);
      this.type = item;
      this.$store.commit("getType", this.type);
      this.id = this.$store.state.Id;
      if (item == 1) {
        this.path = "dutyList";
      } else if (item == 2) {
        this.path = "roles";
      } else if (item == 3) {
        this.path = "punish";
      } else if (item == 4) {
        this.path = "compliance";
      }

      if(localStorage.getItem("tenantId") == 11){
        if (item != 3){
          this.path = "auth";
        }
      }
      this.$router.push({
        path: this.path,
        query: {
          type: this.type,
        },
      });
    },
  },
};
</script>
<style scoped>
.el-select > .el-input > input {
  border: 0;
}
</style>
<style lang="scss" >
// .el-main {
//   width: 97.5%;
//   height: 91%;
//   // height: 975px;
//   margin: auto;
//   background: #ffffff;
//   border-radius: 10px;
//   padding: 0 !important;
//   opacity: 0.8;
//   box-shadow: 0px 0px 20px 0px rgba(21, 33, 65, 0.25);
// }
.main2 {
  width: 37.5%;
  height: 22px;
  margin: 1.19rem auto 0;
}
.main2 p {
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: center;
}
.main3 {
  width: 37.5%;
  height: 22px;
  margin: 0.18rem auto ;
}
.main3 p {
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  font-family: PingFangSC-Medium;
  text-align: center;
  font-weight: 900;
}
.home_box {
  width: 1089px;
  margin: 0.4687rem auto;

}
.home_box > div {
  display: flex;
  height: 290px;
  text-align: center;
  justify-content: space-between;
}
.home_box dl {
  width: 236px;
  height: 290px;
  // display: inline-block;
  // float: left;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  cursor: pointer;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
}
.home_box dl dt {
  height: 89px;
  // margin: 60px auto 20px;
  padding-top: 60px;
  padding-bottom: 20px;
}
.home_box dl dt img{
  max-width: 150px;
  min-width: 50px;
}
.home_box dl dd {
  width: 236px;
}
.home_box dl dd h3 {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #0d1a26;
  text-align: center;
  margin: 12px auto 12px;
}
.home_box dl dd p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #314659;
}
</style>