
<template>
  <div>
    <div>
      <div>
      <!-- 欢迎使用 -->
        <div :class="{'main_background':mainCss,'main_background1':!mainCss}">
          <p id="punish_p">义务清单</p>
          <p>满满干货，明确底线</p>
          <el-button  type="primary" id="backButton" @click="filtrate">
            <img  src="@/assets/image/icon/shaixuan.svg" alt=""/>&nbsp;&nbsp;高级筛选</el-button>

          <div class="main18 flex-col"></div>
          <div style="margin-top: 60px">
            <el-form :model="ruleForm" label-width="80px"  label-position="left">
              <el-form-item
                      :label="nape.name"
                      prop="type"
                      class="backlabel"
                      v-for="(nape, index) in search_tagList"
                      :key="nape.tagId"
              >
                <el-checkbox v-model="nape.checkAll"
                             @change="handleCheckAllChange(index)">全部</el-checkbox>
                <el-checkbox-group
                        v-model="nape.checkedLine"
                        @change=" (value) => { handleCheckedChange(value, index); } ">
                  <el-checkbox
                          v-for="(item, index) in nape.tagDetList"
                          :label="item.tagDetId"
                          :key="index"
                  >{{ item.detName }}</el-checkbox
                  >
                </el-checkbox-group>

                <span v-if="index == 0" @click="filtrate" style="position: absolute;right: 20px"
                >收起 <i class="el-icon-arrow-up"></i>
              </span>
              </el-form-item>

              <el-form-item class="punish_search" style="margin-left: -4.85rem;padding-bottom: 30px">
                <el-button @click="reset" style="border: 1px solid #2696FF;font-weight: bolder;color: #2696FF;">重置</el-button>
                <el-button @click="getDutyList" style="background-color: #2696FFFF;color: #FFFFFF;border: 1px solid #2696FF;">确定</el-button>
              </el-form-item>
            </el-form>

          </div>
        </div>
      </div>
      <!-- 内容框 -->
      <div class="home_box1">
        <div>
          <dl v-for="item in list" :key="item.id" @click="dutyList(item.id)">
            <dd>
              <p>{{ item.name }}</p>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDutyListApi } from "@/api/dutyListApi";
export default {
  data() {
    return {
      value: "",
      id: "",
      type: "",
      list: [],
      mainCss:true,
      //高级筛选下列表
      search_tagList: [],
      //多选
      checkAll: [],
      checkedLine: [],
      // 表单校验
      ruleForm: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
    };
  },
  async mounted() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    let ret = await getDutyListApi({
      lineId: this.id,
      type: this.type,
    });
    if (ret.data.code === 200) {
      this.list = ret.data.data.list;
      this.search_tagList = ret.data.data.tagList;
      this.search_tagList.forEach((item) => {
        this.$set(item, "checkAll", false);
        this.$set(item, "checkedLine", []);
        item.val = true;
      });
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    async getDutyList() {
      var detlist = [];
      [].concat.apply([],this.checkedLine).map((value) =>{
        if(!!value){detlist.push(value)}
      })
      await getDutyListApi({
        type: this.type,
        tagDetIdList: detlist,
      }).then((res) => {
        this.list = res.data.data.list;
        this.mainCss = true;
      });
    },
    dutyList(id) {
      // console.log(id);
      this.$router.push({ path: "dutyList/detail", query: { id: id } });
    },
    // 高级筛选展开
    filtrate() {
      if(this.mainCss){
        this.mainCss = false
      }else{
        this.mainCss = true
      }
    },

    handleCheckAllChange(index) {

      console.info(index);
      console.info(this.search_tagList[index].val);
      if (this.search_tagList[index].val) {
        this.search_tagList[index].checkedLine = [];
        this.search_tagList[index].tagDetList.forEach((item) => {
          this.search_tagList[index].checkedLine.push(item.tagDetId);
        });
        this.search_tagList[index].val = false;
        this.checkedLine[index] = this.search_tagList[index].checkedLine
        //this.checkedLine.push(this.search_tagList[index].checkedLine);
      } else {
        this.search_tagList[index].checkedLine = [];
        this.search_tagList[index].val = true;
        this.checkedLine[index] = [];
        // this.checkedLine.splice(index,1);
      }
      console.info(this.checkedLine);
    },
    // 多选框
    handleCheckedChange(value, index) {
      this.checkedLine = [];
      let Count = value.length;
      this.search_tagList[index].checkAll =
              Count === this.search_tagList[index].tagDetList.length;
      this.search_tagList.forEach((item) => {
        this.checkedLine.push(item.checkedLine);
      });
    },
    // 表单重置
    reset() {
      this.checkedLine = [];
      this.checkAll = [];
      this.search_tagList.map((item) => {
        item.checkedLine = [];
        item.checkAll = false;
        item.val = true;
      });
    },
  },
};
</script>

<style scoped>
@import "../../static/css/page.css";
@import "../../static/css/headerBanner.css";

.el-button--primary {
  background-color: transparent !important;
  color: #2696FF !important;
  font-size: 14px;
  height: 0.1667rem;
  margin-left: 372px;
  padding-top: 4px;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid #2696FF;
}
.main_background> p,.main_background> div{
  width: 60%;
  margin: 0 auto;
}
.main_background > #punish_p {
  margin-top: 48px;
  color: rgba(8, 40, 105, 1);
  font-size: 28px;
  font-weight: 900;
}
.main_background > #punish_p + p {
  color: rgba(8, 40, 105, 1);
  font-size: 16px;
  margin: 14px auto;
}
.main_background {
  background: url("../../assets/image/dutyList/obli.png") no-repeat;
  background-size: 100% ;
  width: 96%;
  height: 1.125rem ;
  overflow: hidden;
  text-align: left;
  position: absolute;
  z-index: 1;
}
.main_background1 {
  background: url("../../assets/image/dutyList/obli.png") no-repeat;
  background-size: 100% ;
  background-color: #E2F0FF;
  width: 96%;
  height: auto;
  overflow: hidden;
  text-align: left;
  position: absolute;
  z-index: 1;
}

.main_background1> p,.main_background1> div{
  width: 60%;
  margin: 0 auto;
}
.main_background1 > #punish_p {
  margin-top: 48px;
  color: rgba(8, 40, 105, 1);
  font-size: 28px;
  font-weight: 900;
}
.main_background1 > #punish_p + p {
  color: rgba(8, 40, 105, 1);
  font-size: 16px;
  margin: 14px auto;
}
.el-checkbox-group {
  display: inline-block;
  vertical-align: top;

}
.punish_search{
  text-align: center;
}
/* 主体展开 */
.el-checkbox-group {
  width: 88%;
}
.el-checkbox:last-of-type {
  margin-right: 17px;
}


.el-form-item{
  margin-bottom: 0px;
}

.main18 {
  width: 1203px;
  height: 1px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngdad15fa71fcebcac6d35f177823d1ab5198025734510cbcaabc2e2a9bdf36d7a)
  100% no-repeat;
  position: absolute;
  top: 216px;
  left: 1.91rem;
}
</style>

<style lang="scss" >


.home_select {
  width: 540px;
  margin: 0 auto;
}
/*  */
.home_box1 {
  width: 1583px;
  margin: 30px ;
  position: absolute;
  z-index: 0;
  left: 195px;
  top: 284px;
}
.home_box1 > div {
  display: flex;
  text-align: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.home_box1 dl {
  margin-bottom: 30px;
  margin-right: 30px;
  height: 186px;
  height: 170px;
  width: 348px;
  background: url("../../assets/image/dutyList/obli-background.png") no-repeat;
  /* background-size: 100% 100%; */
  cursor: pointer;
}
.home_box1 dl dd p {
  color: white;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #ffffff;
  line-height: 186px;
  font-size: 20px;
}
</style>